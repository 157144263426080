<template>
  <data-table v-model="users"></data-table>
</template>

<script>
export default {
  name: 'personal-list',
  props: [],
  components: {},
  title: 'Personal',
  data() {
    return {
      users: {
        title: 'Personal',
        resource: 'personal',
        searchIn: ['search'],
        columns: [
          {
            label: 'Name',
            field: ['first_name', 'last_name'],
            link: 'id',
            type: 'link',
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '30',
          },
          {
            label: 'Geburtsdatum',
            field: ['birth_date'],
            headerAlign: 'left',
            width: '20',
            type: 'text',
          },
          {
            label: 'Eintrittsdatum',
            field: ['start_date'],
            headerAlign: 'left',
            width: '20',
            type: 'text',
          },
          { label: '', field: ['id'], type: 'checkbox', width: '10' },
        ],
        rows: [],
      },
      newPersonal: null,
      breadcrumbs: {
        title: 'Personal',
        actions: {
          title: this.can('Personal', 'Personal', 'Personal', 'edit', ['All'])
            ? 'Personal erfassen'
            : null,
          component: 'new-personal',
          resource: '/personal',
          maxWidth: 'w-full md:w-1/3',
        },
        permission: {
          module: 'Personal',
          subModule: 'Personal',
          childModule: 'Personal',
          operation: 'edit',
          options: ['All'],
        },
        upload: { title: 'Import / Export', link: '/settings/import-export' },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
  },
}
</script>
